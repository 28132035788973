import TickIcon from 'imports/ApplyForm/ui/assets/TickIcon';

export const LOADING_DATA_INFO = [
  {
    icon: <TickIcon />,
    title: 'scan_education',
  },
  {
    icon: <TickIcon />,
    title: 'scan_experience',
  },
  {
    icon: <TickIcon color="#909090" />,
    title: 'scan_skills',
  },
];
