import styled, { css } from 'styled-components';

import TextAtom from '/imports/pdf/core/ui/atoms/Text';

const Text = styled(TextAtom)`
  color: #1c2946;
  ${({ theme, children }) => css`
    font-family: ${theme.contentFont()};
    font-size: ${theme.convertPx(11, true)};
    line-height: 1.6;
  `}
`;

export default Text;
