import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import nookies from 'nookies';
import styled from 'styled-components';
import Flex from '/imports/core/ui/atoms/Flex';
import intlHook from '/imports/core/api/useIntl';
import { useAccount } from '/imports/core/api/accounts/accountContext';
import { setDataRecord, updateUser } from '/imports/carrer/api/api';
import useTracking from '/imports/core/hooks/useTracking';
import { useResponsive } from '/imports/core/api/responsiveContext';
import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import { getJobTitle } from '/imports/carrer/api/helper';
import env from 'env';
import { replaceString } from '/imports/core/ui/helpers';

const Tab4 = (props) => {
  const { resume, hideModal } = props;
  const { trackEvent } = useTracking();
  const { t, locale } = intlHook();
  const { token } = nookies.get({});
  const { currentUser } = useAccount();
  const { isMobile } = useResponsive();
  useEffect(() => {
    trackEvent('proofread_cv_view');
  }, []);

  const handleData = async (key, value) => {
    await updateUser({ [key]: value }, token);
  };

  const handleCareerData = (dontNeeded) => () => {
    if (dontNeeded) {
      trackEvent('proofread_cv', { proofread_cv: 'No' });
      handleData('disableCarrerQuiz', true);
      hideModal();
      return;
    }

    const obj = {
      id: currentUser.id,
      lang: locale,
      resumeUrl: `https://resumedone.co/user-resume/${currentUser.id}/${resume.id}`,
      lastJobTitle: getJobTitle(resume),
      variant: marvelEmitter.getActiveVariant('exp_career_status_v2'),
      isTestEnv: env.NODE_ENV === 'production' ? false : true,
    };
    trackEvent('proofread_cv', { proofread_cv: 'Yes' });
    handleData('disableCarrerQuiz', true);
    setTimeout(() => {
      setDataRecord(obj, token);
    }, 500);
    hideModal();
  };
  return (
    <Fragment>
      <StyledWrapper fullWidth direction="column" alignItems="center">
        <Styledul>
          <Styledli
            dangerouslySetInnerHTML={{
              __html: replaceString(t('career_sub_1'), { 'JOB TITLE': getJobTitle(resume) }),
            }}
          />
          <Styledli dangerouslySetInnerHTML={{ __html: t('career_sub_2') }} />
        </Styledul>
      </StyledWrapper>
      <StyledFlex fullWidth alignItems="center" justifyContent="center">
        <CTA onClick={handleCareerData(true)}>{t('cta_1')}</CTA>
        <CTA dangerouslySetInnerHTML={{ __html: t('cta_2') }} onClick={handleCareerData(false)} />
      </StyledFlex>
    </Fragment>
  );
};

const StyledWrapper = styled(Flex)`
  gap: 24px;
`;

const Styledul = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0;
`;

const Styledli = styled.li`
  color: #262b33;
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 18px;
  span {
    color: #1688fe;
  }
`;

const WrapFlex = styled(Flex)`
  width: 100%;
  position: relative;
`;

const RadioInput = styled.input`
  position: absolute;
  right: 16px;
  z-index: 1;
`;

const CTA = styled.div`
  cursor: pointer;
  text-align: center;
  padding: 16px 24px;
  border-radius: 4px;
  border: 1px solid #1688fe;
  background: var(--light-values-white);
  color: var(--dark-values-black);
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 18px;
  line-height: 24px;
  span {
    color: #1688fe;
  }
`;

const StyledFlex = styled(Flex)`
  gap: 24px;
  margin-top: 24px;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

Tab4.propTypes = {
  resume: PropTypes.object,
  hideModal: PropTypes.func,
};

export default Tab4;
