export const PROMPT_INFORMATION_WITH_CTA = [
  {
    ctaTitle: 'Load more',
    prompt: 'prompt_load_more_cta',
    category: 'suggestions',
    ctaslug: 'load_more_cta_txt',
  },
  {
    ctaTitle: 'Suggestions with leadership',
    prompt: 'prompt_leadership_cta',
    category: 'suggestions',
    ctaslug: 'suggestion_with_leadership_cta_txt',
  },
  {
    ctaTitle: 'Suggestions with technical skills',
    prompt: 'prompt_technical_cta',
    category: 'suggestions',
    ctaslug: 'suggestion_with_technical_skills_cta_txt',
  },
  {
    ctaTitle: 'Suggestions with innovation',
    prompt: 'prompt_innovation_cta',
    category: 'suggestions',
    ctaslug: 'suggestion_with_innovation_cta_txt',
  },
  {
    ctaTitle: 'Quantifiable Achievements',
    prompt: 'prompt_achievements_cta',
    category: 'suggestions',
    ctaslug: 'quantifiable_achievement_cta_txt',
  },
];
