import { Experiment as MarvelExperiment, Variant as MarvelVariant } from '@marvelapp/react-ab-test';
import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import { withRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { Fragment, PureComponent, useEffect } from 'react';
import { graphql } from 'react-apollo';
import { withAccount } from '/imports/core/api/accounts/accountContext';
import { withGeneralContext } from '/imports/core/api/generalContext';
import { withResponsiveContext, useResponsive } from '/imports/core/api/responsiveContext';
import useIntl, { withIntl } from '/imports/core/api/useIntl';
import useTracking, { withTracking } from '/imports/core/hooks/useTracking';
import { UPDATE_RESUME_DETAIL } from '/imports/generator/api/apollo/client/mutations';
import WizardHeader from '/imports/generator/ui/atoms/WizardHeader';
import WizardSubHeader from '/imports/generator/ui/atoms/WizardSubHeader';
import GenericBlock from '/imports/generator/ui/components/GenericBlock';
import WizardExperienceIntro from '/imports/generator/ui/components/WizardExperienceIntro';
import WizardLayout from '/imports/generator/ui/layouts/WizardLayout';
import { removeExperiment } from '/lib/helpers';

const ExperienceWizardForm = ({ t, renderBlock, designV2, resume }) => {
  const { trackEvent } = useTracking();
  const { isMobile } = useResponsive();
  const { fromScan } = resume;
  const { locale } = useIntl();

  useEffect(() => {
    let obj = {
      resume_language: resume.settings.language,
    };
    if (marvelEmitter.getActiveVariant('exp_show_ai_popup') && !isMobile) {
      obj.exp_show_ai_popup = marvelEmitter.getActiveVariant('exp_show_ai_popup');
    }
    if (marvelEmitter.getActiveVariant('job_title_autocomplete_exp_en_new')) {
      obj.job_title_autocomplete_exp_en_new = marvelEmitter.getActiveVariant('job_title_autocomplete_exp_en_new');
    }
    if (marvelEmitter.getActiveVariant('exp_ideas_suggestion_prompt')) {
      obj.exp_ideas_suggestion_prompt = marvelEmitter.getActiveVariant('exp_ideas_suggestion_prompt');
    }
    if (marvelEmitter.getActiveVariant('exp_rephrasing_prompt')) {
      obj.exp_rephrasing_prompt = marvelEmitter.getActiveVariant('exp_rephrasing_prompt');
    }
    if (marvelEmitter.getActiveVariant('exp_selected_text_ai')) {
      obj.exp_selected_text_ai = marvelEmitter.getActiveVariant('exp_selected_text_ai');
    }
    if (marvelEmitter.getActiveVariant('exp_cta_feedback_ai')) {
      obj.exp_cta_feedback_ai = marvelEmitter.getActiveVariant('exp_cta_feedback_ai');
    }

    if (isMobile) removeExperiment('exp_show_ai_popup');
    removeExperiment('exp_undo_redo_textarea_editor');
    trackEvent('experience_view', obj);
  }, []);

  return (
    <Fragment>
      <WizardHeader designV2={designV2}>
        <div dangerouslySetInnerHTML={{ __html: t('generator.experience.form_title') }} />
      </WizardHeader>
      <WizardSubHeader marginLess fromScan={fromScan}>
        {t('generator.experience.form_subtitle')}
      </WizardSubHeader>
      {renderBlock()}
    </Fragment>
  );
};

@withTracking
@withIntl
@withRouter
@withAccount
@withResponsiveContext
@withGeneralContext
@graphql(UPDATE_RESUME_DETAIL, { name: 'updateResumeDetail' })
class WizardExperience extends PureComponent {
  static propTypes = {
    updateResumeDetail: PropTypes.func,
    intro: PropTypes.string,
    resume: PropTypes.object,
    currentUser: PropTypes.object,
    isFormValid: PropTypes.bool,
  };

  state = {
    show: false,
    popshown: false,
  };
  componentDidMount() {
    const { currentUser, trackUserIdentity } = this.props;
    trackUserIdentity(currentUser, true, {}, false);
  }

  _form = null;

  getForm = (node) => {
    this._form = node;
  };

  isFormValid = () => (this._form && this._form.isFormValid(false)) || Promise.resolve(true);

  onSubmit = () => {
    const {
      intro,
      resume: {
        id,
        blocks,
        details: { title },
      },
      updateResumeDetail,
    } = this.props;

    if (!intro) {
      const block = blocks.find((block) => block.type === 'EMPLOYMENT');
      const item = block.items && block.items[0];
      const jobTitle = item && item.fields && item.fields.title;

      if (!title && jobTitle) {
        updateResumeDetail({
          variables: {
            docId: id,
            path: 'details.title',
            value: jobTitle,
          },
        });
      }
    }
  };

  renderBlock = () => {
    const { resume, updateImmue, referenceResume } = this.props;
    const block = resume.blocks.find((block) => block.type === 'EMPLOYMENT');
    const refernceBlock = referenceResume && referenceResume.blocks.find((block) => block.type === 'EMPLOYMENT');
    return (
      <GenericBlock
        key={'random_id'}
        block={block}
        resumeId={resume.id}
        source={resume}
        noTitle
        referenceResume={referenceResume}
        refernceBlock={refernceBlock}
        getForm={this.getForm}
        isFormValid={this.props.isFormValid}
        updateImmue={updateImmue}
      />
    );
  };

  render() {
    const { resume, intro, designV2, updateImmue } = this.props;
    return (
      <WizardLayout
        isFormValid={this.props.isFormValid}
        resume={resume}
        onSubmit={this.onSubmit}
        updateImmue={updateImmue}
      >
        {intro && intro === 'intro' ? (
          <WizardExperienceIntro />
        ) : (
          <ExperienceWizardForm renderBlock={this.renderBlock} {...this.props} designV2={designV2} />
        )}
      </WizardLayout>
    );
  }
}

const RenderAIPromptCTAExp = (props) => {
  return (
    <MarvelExperiment name="exp_cta_feedback_ai">
      <MarvelVariant name="control">
        <WizardExperience {...props} />
      </MarvelVariant>
      <MarvelVariant name="with_new_ctas">
        <WizardExperience {...props} />
      </MarvelVariant>
    </MarvelExperiment>
  );
};

const RenderAiPopup = (props) => {
  const { isMobile } = useResponsive();
  if (isMobile) return <RenderAIPromptCTAExp {...props} />;
  return (
    <MarvelExperiment name="exp_show_ai_popup">
      <MarvelVariant name="control">
        <RenderAIPromptCTAExp {...props} />
      </MarvelVariant>
      <MarvelVariant name="show_popup">
        <RenderAIPromptCTAExp {...props} />
      </MarvelVariant>
    </MarvelExperiment>
  );
};

WizardExperience.displayName = 'WizardExperience';

export default RenderAiPopup;
