import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import { parseDraftText } from 'lib/helpers';
import _ from 'lodash';
import dynamic from 'next/dynamic';
import { Fragment, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import useIntl from '/imports/core/api/useIntl';
import useTracking from '/imports/core/hooks/useTracking';
import BluePlusIcon from '/imports/core/ui/atoms/BluePlusIcon';
import CircleArrowLeft from '/imports/core/ui/atoms/CircleArrowLeft';
import Flex from '/imports/core/ui/atoms/Flex';
import GreyMinusIcon from '/imports/core/ui/atoms/GreyMinusIcon';
import Modal from '/imports/core/ui/atoms/Modal';
import { PROMPT_INFORMATION_WITH_CTA } from '/imports/generator/api/promptData';
import animationDataLonger from '/imports/generator/ui/assets/LottieLoaderLonger.json';
import CrossIcon from '/imports/generator/ui/atoms/CrossIcon';
import CrossSwapIcon from '/imports/generator/ui/atoms/CrossSwapIcon';

const Lottie = dynamic(() => import('react-lottie'), { ssr: false });

const modalStyles = {
  modalContainer: {
    display: 'flex',
    scrollY: 'auto',
  },
  modalBackdrop: { background: 'rgba(52, 60, 73, 0.80)' },
  modalBody: {
    width: '420px',
    maxHeight: '500px',
    backgroundColor: 'var(--light-values-white)',
    borderRadius: '12px',
    boxShadow: '0 12px 48px 0 rgba(20, 20, 31, 0.24)',
    flex: 'unset',
    overflow: 'visible',
  },
};
const AISuggestionModal = ({
  type,
  open,
  setOpen,
  modalPosition,
  isMobile,
  loading,
  setLoading,
  suggestions,
  rephrasing,
  setRephrasing,
  updateSelectedAISuggestion,
  withRephrasing,
  handleSend,
}) => {
  const { t } = useIntl();
  const [suggestionsSelectedState, setSuggestionsSelectedState] = useState([]);
  const [rephrasingSelectedState, setRephrasingSelectedState] = useState(false);
  const { trackEvent } = useTracking();
  const showRephrasing = useMemo(() => withRephrasing && rephrasing, [withRephrasing, rephrasing]);
  const isSelectedTxtVar = marvelEmitter.getActiveVariant('exp_selected_text_ai') === 'with_selected_text';

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationDataLonger,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  if (isMobile) {
    modalStyles.modalBody = {
      ...modalStyles.modalBody,
      margin: 'auto',
      width: '90%',
      maxHeight: '90%',
    };
  } else {
    modalStyles.modalContainer = {
      ...modalStyles.modalContainer,
      width: 'unset',
      bottom: 'unset',
      top: modalPosition.top,
      left: modalPosition.left,
    };
    modalStyles.modalBackdrop = {
      ...modalStyles.modalBackdrop,
      display: 'none',
    };
  }
  const handleClose = () => {
    setOpen(false);
  };

  const unselectAllSuggestions = () => {
    setSuggestionsSelectedState(suggestions.map(() => false));
  };
  const selectSuggestion = (formattedSuggestion, suggestionIndex) => {
    updateSelectedAISuggestion(formattedSuggestion, isMobile && suggestionsSelectedState[suggestionIndex]);
    if (isMobile) {
      setSuggestionsSelectedState((prevState) =>
        prevState.map((value, index) => (index === suggestionIndex ? !value : value)),
      );
    }
  };
  const handleClickSelectSuggestion = _.debounce(selectSuggestion, isMobile ? 200 : 0);

  const selectRephrasing = (rephrasing) => {
    updateSelectedAISuggestion(rephrasing, (isMobile || isSelectedTxtVar) && rephrasingSelectedState, true);
    if (isMobile || isSelectedTxtVar) {
      if (!rephrasingSelectedState) {
        unselectAllSuggestions();
      }
      setRephrasingSelectedState((prevState) => !prevState);
    }
  };
  const handleClickSelectRephrasing = _.debounce(selectRephrasing, isMobile ? 200 : 0);

  const handleClickDone = () => {
    trackEvent('done_cta_click');
    handleClose();
  };

  const formatSuggestion = (text) => {
    const bulletCharacters = '•‣⁃⁍⁕*⁘⁜⦾⦿◦-';
    return text.replace(new RegExp(`^\\d*\\s*[${bulletCharacters}]`), '');
  };

  useEffect(() => {
    const hasElement = (suggestions || []).some((element) => !!element);
    if (hasElement && open) {
      trackEvent('ai_suggestions_loaded_sucess');
      unselectAllSuggestions();
    }
  }, [suggestions]);

  const onPopupExited = () => {
    setRephrasing('');
    setLoading(false);
  };

  useEffect(() => {
    if (showRephrasing && open) {
      setRephrasingSelectedState(false);
    }
  }, [rephrasing]);

  const isNewCTAsVar = marvelEmitter.getActiveVariant('exp_cta_feedback_ai') === 'with_new_ctas';
  const isShowButtonSuggestions = isNewCTAsVar && type === 'EMPLOYMENT';

  const TriggerAIAPICall = (data) => () => {
    trackEvent('feedback_cta_click', {
      ctatitle: data.ctaTitle,
    });
    handleSend(data);
  };

  const handleCopyText = () => {
    trackEvent('ai_copy_rephrasing');
    const parseData = parseDraftText(rephrasing, true);
    navigator?.clipboard?.writeText(parseData);
  };

  return (
    <Modal
      onClose={() => handleClose()}
      styles={modalStyles}
      animation="empty"
      openStateBase
      open={open}
      timeout={0}
      className="custom"
      noClicker
      onExited={onPopupExited}
    >
      <Wrapper>
        {loading ? (
          <LoadingWrapper>
            <CrossIconWrapper $topFixed onClick={handleClose}>
              <CrossIcon />
            </CrossIconWrapper>
            <Lottie options={defaultOptions} height={100} width={100} />
            <LoadingText>{t('generator_ai_suggestion_loading_text_v2')}</LoadingText>
          </LoadingWrapper>
        ) : (
          <>
            <ScrollWrapper fullWidth>
              {showRephrasing && (
                <>
                  <HeaderFlex fullWidth>
                    <Flex justifyContent="space-between" alignItems="center" fullWidth>
                      <HeadText>{t('generator_ai_rephrasing_modal_headtext')}</HeadText>
                      <CrossIconWrapper onClick={handleClose}>
                        <CrossIcon />
                      </CrossIconWrapper>
                    </Flex>
                    <Subtitle>{t('generator_ai_rephrasing_modal_text')}</Subtitle>
                  </HeaderFlex>
                  <SuggestionsWrapper fullWidth>
                    <SuggestionWrapper
                      alignItems="flex-start"
                      fullWidth
                      $allowSelect={isSelectedTxtVar}
                      onClick={() => {
                        if (!isSelectedTxtVar) {
                          handleClickSelectRephrasing(rephrasing);
                        } else {
                          handleCopyText();
                        }
                      }}
                      iconV2
                      isMobile={isMobile}
                    >
                      <IconWrapper
                        $isSelectedTxtVar={isSelectedTxtVar}
                        onClick={() => {
                          if (isSelectedTxtVar) {
                            handleClickSelectRephrasing(rephrasing);
                          }
                        }}
                      >
                        {isMobile || isSelectedTxtVar ? (
                          <>{!!rephrasingSelectedState ? <GreyMinusIcon /> : <BluePlusIcon />}</>
                        ) : (
                          <CrossSwapIcon />
                        )}
                      </IconWrapper>
                      <SuggestionText
                        selected={isMobile && !!rephrasingSelectedState}
                        $allowSelect={isSelectedTxtVar}
                        dangerouslySetInnerHTML={{
                          __html: rephrasing.replaceAll('\n', '<br/>'),
                        }}
                      />
                    </SuggestionWrapper>
                  </SuggestionsWrapper>
                  <Separator />
                </>
              )}

              <HeaderFlex fullWidth>
                <Flex justifyContent="space-between" alignItems="center" fullWidth>
                  <HeadText>{t('generator_ai_suggestion_modal_headtext_v2')}</HeadText>
                  {!showRephrasing && (
                    <CrossIconWrapper onClick={handleClose}>
                      <CrossIcon />
                    </CrossIconWrapper>
                  )}
                </Flex>
                <Subtitle>{t('generator_ai_suggestion_modal_text_v2')}</Subtitle>
              </HeaderFlex>
              <SuggestionsWrapper fullWidth>
                {suggestions.map((suggestion, i) => {
                  const formattedSuggestion = formatSuggestion(suggestion);
                  return (
                    <SuggestionWrapper
                      alignItems="flex-start"
                      key={i}
                      fullWidth
                      onClick={() => handleClickSelectSuggestion(formattedSuggestion, i)}
                      isMobile={isMobile}
                      iconV1
                    >
                      <IconWrapper isMobile={isMobile}>
                        {isMobile ? (
                          <>{!!suggestionsSelectedState[i] ? <GreyMinusIcon /> : <BluePlusIcon />}</>
                        ) : (
                          <CircleArrowLeft />
                        )}
                      </IconWrapper>
                      <SuggestionText selected={isMobile && !!suggestionsSelectedState[i]}>
                        {formattedSuggestion}
                      </SuggestionText>
                    </SuggestionWrapper>
                  );
                })}
              </SuggestionsWrapper>
              {isShowButtonSuggestions && (
                <Fragment>
                  <NewButtonBlock $fullWidth direction="column">
                    <NewTitle>{t('set_imporvement_txt')}</NewTitle>
                    <CtaWrapperGroup wrap="wrap">
                      {PROMPT_INFORMATION_WITH_CTA.map((data, index) => (
                        <NewCTAS key={index} onClick={TriggerAIAPICall(data)}>
                          {t(data.ctaslug)}
                        </NewCTAS>
                      ))}
                    </CtaWrapperGroup>
                  </NewButtonBlock>
                </Fragment>
              )}
            </ScrollWrapper>
            {isMobile && (
              <MobileFooterWrapper fullWidth>
                <DoneCTA onClick={handleClickDone}>{t('generator_ai_suggestion_done_cta')}</DoneCTA>
              </MobileFooterWrapper>
            )}
          </>
        )}
      </Wrapper>
    </Modal>
  );
};
export default AISuggestionModal;

const NewButtonBlock = styled(Flex)`
  border-radius: 8px;
  background: #f7f7fc;
  padding: 9px;
  gap: 12px;
  width: 98%;
  margin-top: 10px;
`;

const NewTitle = styled.div`
  color: #a1a1a5;
  font-size: 13px;
  line-height: 20px;
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  width: 100%;
`;

const CtaWrapperGroup = styled(Flex)`
  gap: 8px;
`;

const NewCTAS = styled(Flex)`
  padding: 6px 16px;
  border-radius: 4px;
  border: 1px solid #1688fe;
  background: #f4f9ff;
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  color: #1688fe;
  font-size: 13px;
  line-height: 20px;
  cursor: pointer;
`;

const MobileFooterWrapper = styled(Flex)`
  ${({ theme: { isRTL } }) => (isRTL ? `padding: 0 0 0 12px;` : `padding: 0 12px 0 0;`)}
`;
const DoneCTA = styled.button`
  display: flex;
  width: 100%;
  height: 40px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  background-color: #1688fe;

  color: #fff;
  font-family: Gilroy SemiBold;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;

  border: none;
  border-radius: 4px;
  background: var(--Primary-B400-Default, #1688fe);
  box-shadow: 0px 6px 24px 0px rgba(20, 20, 31, 0.12);

  cursor: pointer;

  &:hover {
    background-color: #0072e8;
  }
`;
const Separator = styled.div`
  width: 100%;
  padding-top: 1px;
  background: #e3e3e4;
  margin: 24px 0;
`;
const ScrollWrapper = styled(Flex)`
  flex-direction: column;
  overflow-y: auto;
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 10px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${(p) => p.theme.colors.gray.regular};
  }
`;
const LoadingWrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  padding: 20%;
  gap: 16px;
`;
const LoadingText = styled.span`
  color: #1688fe;
  text-align: center;
  font-family: Gilroy Regular;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
`;
const Block = styled(Flex)`
  display: block;
  width: 100%;
  > span > span {
    height: 50px;
  }
`;
const CrossIconWrapper = styled(Flex)`
  ${(p) =>
    p.$topFixed &&
    css`
      position: absolute;
      top: 0;
      right: 0;
      ${p.theme.isRTL &&
      css`
        right: unset;
        left: 0;
      `}
    `}
  flex-shrink: 0;
  cursor: pointer;
`;
const Wrapper = styled(Flex)`
  flex-direction: column;
  padding: 16px 4px 16px 16px;
  gap: 12px;
  max-height: 500px;

  @media (max-width: 800px) {
    max-height: 100%;
  }
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
      padding: 16px 16px 16px 4px;
    `}
`;
const HeaderFlex = styled(Flex)`
  flex-direction: column;
  gap: 4px;
`;
const HeadText = styled.span`
  color: #14141f;
  font-family: Gilroy SemiBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
`;
const Subtitle = styled.span`
  color: #a1a1a5;
  font-family: Gilroy SemiBold;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;
const SuggestionsWrapper = styled(Flex)`
  flex-direction: column;
  margin-top: 12px;
`;
const SuggestionWrapper = styled(Flex)`
  cursor: pointer;
  padding: 8px;
  gap: 8px;
  ${({ isMobile }) =>
    isMobile &&
    css`
      &:active {
        background: #edf4ff;
      }
    `}
  &:hover {
    ${(p) =>
      !p.isMobile &&
      css`
        background: #edf4ff;
      `}
    border-radius: 4px;
    path:first-child {
      fill: #1688fe;
    }
    ${(p) =>
      p.iconV1 &&
      !p.isMobile &&
      css`
        path:nth-child(2),
        path:nth-child(3) {
          stroke: white;
        }
      `}
    ${(p) =>
      p.iconV2 &&
      !p.isMobile &&
      css`
        g > path:nth-child(1),
        path:nth-child(2) {
          fill: white;
        }
      `}
  }

  ${({ $allowSelect }) =>
    $allowSelect &&
    css`
      cursor: text;
      *:not(input, textarea) {
        user-select: text !important;
        -moz-user-select: text !important;
        -webkit-user-select: text !important;
      }
    `}
`;
const IconWrapper = styled(Flex)`
  flex-shrink: 0;
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      transform: rotate(180deg);
    `}

  ${({ $isSelectedTxtVar }) =>
    $isSelectedTxtVar &&
    css`
      cursor: pointer;
    `}
`;
const SuggestionText = styled.span`
  padding-top: 0;
  color: #3a3a43;
  font-family: Gilroy Medium;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  ${({ selected }) =>
    selected &&
    css`
      color: #a1a1a5;
    `}
`;
